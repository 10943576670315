export const initActiveNav = () => {
  const topNavElem = document.querySelector('#top-nav');
  const links = topNavElem.querySelectorAll('.RS-top-bar__elem > .RS-link');
  const currentPageLocation = window.location.href;
  const basePageLocation = window.location.origin + '/';

  links.forEach(link => {
    link.classList.remove('RS-link--active');
    const linkHref = link.getAttribute('href');

    if (linkHref === '/' && currentPageLocation === basePageLocation) {
      link.classList.add('RS-link--active');
    } else if (linkHref !== '/' && currentPageLocation.includes(linkHref)) {
      link.classList.add('RS-link--active');
    }
  });

};

export const initCtaNav = () => {

  const ctaConfig = {
    '': {
      href: 'https://redsky.recruitee.com/o/be-a-founder/c/new',
      text: 'Join as a founder',
      ariaLabel: 'Join as a founder',
    },
    'careers': {
      href: '#RS-section-careers-job-openings',
      text: 'Job Openings',
      ariaLabel: 'Job Openings',
    },
    'founder': {
      href: 'https://redsky.recruitee.com/o/be-a-founder/c/new',
      text: 'Apply now',
      ariaLabel: 'Apply now',
    },
    'founder/ai': {
      href: 'https://redsky.recruitee.com/o/be-the-ceo-new-startup-ai-agents',
      text: 'Apply now',
      ariaLabel: 'Apply now',
    },
    'founder/ai-infrastructure': {
      href: 'https://redsky.recruitee.com/o/be-the-ceo-new-cloud-services',
      text: 'Apply now',
      ariaLabel: 'Apply now',
    },
    'founder/climate': {
      href: 'https://redsky.recruitee.com/o/be-the-ceo-new-startup-climate-tech',
      text: 'Apply now',
      ariaLabel: 'Apply now',
    },
    'founder/cybersecurity': {
      href: 'https://redsky.recruitee.com/o/be-the-ceo-new-startup-cybersecurity',
      text: 'Apply now',
      ariaLabel: 'Apply now',
    },
    'founder/healthcare': {
      href: 'https://redsky.recruitee.com/o/founder-new-startup-healthcare-medtech',
      text: 'Apply now',
      ariaLabel: 'Apply now',
    },
    'newsletter': {
      href: 'https://redsky.recruitee.com/o/be-a-founder/c/new',
      text: 'Join as a founder',
      ariaLabel: 'Join as a founder',
    },
    'thank-you': {
      href: 'https://redsky.recruitee.com/o/be-a-founder/c/new',
      text: 'Join as a founder',
      ariaLabel: 'Join as a founder',
    },
  };

  const ctaElem = document.querySelector('#RS-top-bar__cta > a');
  const ctaElemFounderSectionCompany = document.getElementById('RS-button-founder-recruitment-steps');
  const ctaElemFounderSectionCta = document.getElementById('RS-button-founder-section-cta');


  function normalizePath(path) {
    return path.replace(/^\/|\/$/g, '').toLowerCase();
  }
  
  const currentPage = normalizePath(window.location.pathname);
  const ctaProps = ctaConfig[currentPage];


  // console.log(ctaProps);

  if (ctaProps) {
    ctaElem.setAttribute('href', ctaProps.href);
    ctaElem.setAttribute('aria-label', ctaProps.ariaLabel);
    ctaElem.querySelector('span').textContent = ctaProps.text;
    if (ctaElemFounderSectionCompany) {
      ctaElemFounderSectionCompany.setAttribute('href', ctaProps.href);
    }
    if (ctaElemFounderSectionCta) {
      ctaElemFounderSectionCta.setAttribute('href', ctaProps.href);
    }
  }

  if (currentPage === '/careers') {
    ctaElem.classList.add('RS-button--internal');
    ctaElem.classList.remove('RS-button--external');
  } else {
    ctaElem.classList.remove('RS-button--internal');
    ctaElem.classList.add('RS-button--external');
  }
  
};

export const initDropdownNav = () => {

  if ('ontouchstart' in window) {
    return;
  }

  const dropdownTrigger = document.querySelector('#RS-top-bar__dropdown-trigger');
  const dropdownElem = document.querySelector('#RS-top-bar__dropdown');
  const delay = 15;
  let isDropdownAlreadyVisible = false;

  const toggleDropdown = (isVisible) => {
    if (isVisible && !isDropdownAlreadyVisible) {
      isDropdownAlreadyVisible = true;
      dropdownElem.classList.add('RS-top-bar__dropdown--displayed');
      setTimeout(() => {
        requestAnimationFrame(() => {
          dropdownElem.classList.add('RS-top-bar__dropdown--visible');
        });
      }, delay); 
    } else if (!isVisible && isDropdownAlreadyVisible) {
      isDropdownAlreadyVisible = false;
      dropdownElem.classList.remove('RS-top-bar__dropdown--displayed');
      dropdownElem.classList.remove('RS-top-bar__dropdown--visible');
    }
  };


  const addDropdownListeners = () => {
    dropdownTrigger.addEventListener('mouseenter', () => toggleDropdown(true));
    dropdownTrigger.addEventListener('mouseleave', () => toggleDropdown(false));
    window.removeEventListener('mousemove', addDropdownListeners);
  };

  window.addEventListener('mousemove', addDropdownListeners);

  // const removeDropdownListeners = () => {
  //   dropdownTrigger.removeEventListener('mouseenter', () => toggleDropdown(true));
  //   dropdownTrigger.removeEventListener('mouseleave', () => toggleDropdown(false));
  // };

  // window.addEventListener('resize', () => {
  //   if (window.innerWidth < 860) {  
  //     removeDropdownListeners();
  //   } else {
  //     addDropdownListeners();
  //   }
  // });

};


export const initMobileNav = () => {
  const mobileNavIcon = document.getElementById("mobile-nav-icon");
  const topNavEl = document.getElementById("top-nav");
  const siteBgEl = document.getElementById("RS-background");
  const topNavLinks = topNavEl.querySelectorAll(".RS-link, .RS-button-link");
  const topNavAnimDuration = 225;

  const toggleMobileNav = () => {
    if (mobileNavIcon.classList.contains("RS-top-bar__mobile-nav-icon--active") === false) {
      mobileNavIcon.classList.add("RS-top-bar__mobile-nav-icon--active");
      topNavEl.classList.add("RS-top-bar__nav--displayed");
      if(siteBgEl != null){
        siteBgEl.classList.add('RS-background--elevated');
      }
      process.nextTick(() => topNavEl.classList.add("RS-top-bar__nav--opened"));
      setTimeout(() => {
        topNavLinks.forEach((topNavLink) => {
          topNavLink.classList.add("RS-link--visible")
        })
      }, topNavAnimDuration);

    } else {
      mobileNavIcon.classList.remove("RS-top-bar__mobile-nav-icon--active");
      topNavEl.classList.remove("RS-top-bar__nav--opened");
      if(siteBgEl != null){
        siteBgEl.classList.remove('RS-background--elevated');
      }
      setTimeout(() => {
        topNavLinks.forEach((topNavLink) => {
          topNavLink.classList.remove("RS-link--visible")
        })
      }, topNavAnimDuration);
    }
  };
  mobileNavIcon.addEventListener("click", toggleMobileNav);
  mobileNavIcon.addEventListener("keydown", (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) toggleMobileNav();
  });



  topNavLinks.forEach((topNavLink) => {
    topNavLink.addEventListener("click", toggleMobileNav);
    topNavLink.addEventListener("keydown", (e) => {
      const code = e.keyCode || e.which;
      if (code === 13) toggleMobileNav();
    });
  });

};
